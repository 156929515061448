<template>
  <div class="d-flex flex-column full-height overflow-hidden">
    <module-navigation-bar title="DHME Project Planning">
      <template slot="module-nav-logo">
        <img
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <div
      v-if="dhmeProjectPlanningTasksStatus === 'success'"
      class="flex-grow-1 ma-0 pa-0 overflow-hidden d-flex"
    >
      <add-task-dialog
        ref="add-task-dialog"
        :return-create="true"
        :project="project"
        @createTask="createProjectPlanningTask"
        @closeDialog="closeAddTaskDialog"
      >
        <template #add-task-options>
          <v-form ref="task-options-form" class="d-flex flex-1">
            <ant-input label="Type" class="mr-2">
              <template #input-field>
                <v-combobox
                  v-model="taskType.type"
                  filled
                  placeholder="Type"
                  :items="taskTypes"
                  item-text="text"
                  item-value="value"
                  :rules="[rules.required]"
                  hide-details
                  dense
                />
              </template>
            </ant-input>
            <ant-input
              v-if="
                taskType.type
                  ? taskType.type.value === 'dhme-placement-phase'
                  : false
              "
              label="Number"
            >
              <template #input-field>
                <v-text-field
                  v-model="taskType.number"
                  filled
                  :rules="[rules.required]"
                  type="number"
                  placeholder="Number"
                  hide-details
                  dense
                />
              </template>
            </ant-input>
          </v-form>
        </template>
      </add-task-dialog>
      <div class="flex-1 flex-scroll-width overflow-x-auto">
        <tasks-gantt-chart ref="tasks-gantt" :tasks="dhmeProjectPlanningTasks">
          <template #ant-gantt-actions>
            <v-icon
              class="mr-2"
              small
              @click="$refs['add-task-dialog'].displayed = true"
              >mdi-plus</v-icon
            >
          </template>
          <template #task-header-items>
            <tasks-gantt-default-item
              v-for="(task, index) in dhmeProjectPlanningTasks"
              :key="`ant-gantt-header-${task.id}`"
              :task="task"
              :index="index"
              :parents="[]"
              :show-labels="
                $refs['tasks-gantt'] ? $refs['tasks-gantt'].showLabels : false
              "
              :can-load-children="false"
              @clickedTask="openTaskDetail"
            />
          </template>
        </tasks-gantt-chart>
      </div>
    </div>
    <div v-else class="flex-1 d-flex align-center justify-center">
      <ant-loading />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AddTaskDialog from '@/components/Tasks/AddTaskDialog';
import TasksGanttChart from '@/components/Charts/TasksGanttChart.vue';
import TasksGanttDefaultItem from '@/components/Charts/TasksGanttDefaultItem';
import AntLoading from '@/components/AntLoading';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'DHMEProjectPhasePlanning',
  components: {
    AntInput,
    AntLoading,
    TasksGanttDefaultItem,
    TasksGanttChart,
    AddTaskDialog,
    ModuleNavigationBar: () =>
      import('../../components/Modules/ModuleNavigationBar'),
  },
  data() {
    return {
      addTaskDialog: false,
      taskTypes: [
        {
          text: 'WVB',
          value: 'dhme-wvb-phase',
        },
        {
          text: 'Production',
          value: 'dhme-production-phase',
        },
        {
          text: 'Assembly',
          value: 'dhme-assembly-phase',
        },
        {
          text: 'Placement',
          value: 'dhme-placement-phase',
        },
      ],
      taskType: {},
      rules: {
        required: (value) => !!value || 'Required.',
        maxLength: (value) =>
          value ? value.length < 250 || 'Max 250 characters' : true,
      },
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'selectedLicense',
      'dhmeProjectPlanningTasks',
      'dhmeProjectPlanningTasksStatus',
    ]),
  },
  mounted() {
    this.$store.dispatch('fetchProjectPlanningTasks');
  },
  methods: {
    openTaskDetail(event) {
      this.$router.push({ name: 'tasks-detail', params: { taskId: event.id } });
    },
    closeAddTaskDialog() {
      this.taskType = {};
      this.$refs['task-options-form'].reset();
    },
    createProjectPlanningTask(body) {
      if (this.$refs['task-options-form'].validate()) {
        body.options = {
          type: this.taskType.type.value,
          custom_1: this.taskType.number,
        };

        this.$refs['add-task-dialog'].requestStatus = 'loading';
        this.$store
          .dispatch('createProjectPlanningTask', { body })
          .then((task) => {
            this.$refs['add-task-dialog'].requestStatus = 'success';
            this.$refs['add-task-dialog'].closeDialog();
          })
          .catch((error) => {
            this.$refs['add-task-dialog'].requestStatus = 'error';
          });
      }
    },
  },
};
</script>
